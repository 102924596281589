<template>
	<div class="container-fluid animatedParent animateOnce my-3">
		<!-- main content -->
		<div class="tab-content">
			<div class="tab-pane animated fadeInUpShort show active go">

				<!-- <div class="alert alert-danger alert-dismissible" role="alert">
					<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span>
					</button>
					<strong>通知：</strong> XXX。
				</div> -->

				<div class="row my-3">
					<div class="col-md-12">
						<div class="card r-0 shadow">
							<div class="card-header">

								<div class="row">
									<div class="col-md-11">
										<!-- 搜索 start -->
										<div class="form-inline mt-3">
											<input v-model="query" class="form-control p-2" placeholder="搜索批次号"
												type="text">

											<!-- <date-picker class="ml-2" v-model="start_date" format="YYYY-MM-DD" value-type="format"
												input-class="form-control" :editable="false" :clearable="true" placeholder="有效期开始时间">
											</date-picker>
											<div class="ml-2">
												<date-picker v-model="end_date" format="YYYY-MM-DD" value-type="format"
													input-class="form-control" :editable="false" :clearable="true" placeholder="有效期结束时间">
												</date-picker>
											</div> -->
											
											<input v-model="price" class="form-control p-2 ml-2" placeholder="搜索面值"
												type="text">

											<span v-if="user_role== 'ADMIN'">
												<select v-model="dept_id" class="form-control  ml-2">
													<option value="0">所有企业</option>
													<option v-bind:key="index" :value="item.id"
														v-for="(item,index) in departmentlist">
														{{item.department_name}}
													</option>
												</select>
											</span>

											<select v-model="category" class="form-control ml-2">
												<option value="">所有分类</option>
												<option v-bind:key="index" :value="item.value"
													v-for="(item,index) in categorylist">
													{{item.name}}
												</option>
											</select>

											<select v-model="card_type" class="form-control ml-2">
												<option value="">所有类型</option>
												<option v-bind:key="index" :value="item.value"
													v-for="(item,index) in cardtypelist">
													{{item.name}}
												</option>
											</select>

											<button @click="search" type="button" class="btn btn-secondary  ml-2"><i
													class="icon-search"></i>
											</button>

											<!-- <button @click="search" type="button" class="btn btn-xs btn-link  ml-2">
												查看过期制度
											</button> -->
											<!-- <div class="form-check ml-2">
												<label class="form-check-label">
													<input 
													v-model="expired" 
													class="form-check-input" type="checkbox"> 包括过期失效制度
												</label>
											</div> -->
										</div>
										<!-- 搜索 end -->
									</div>
									<div class="col-md-1">
										<!-- <div class="float-right m-3">
											<button v-if="user_role== 'ADMIN'" @click="add" type="button"
												class="btn btn-secondary">导出</button>
										</div> -->
									</div>
								</div>
							</div>

							<div class="table-responsive">
								<div v-if="giftcardstocklist.length > 0">
									<table class="table table-striped table-hover r-0  mb-0">
										<thead>
											<tr class="no-b">
												<th style="width: 60px">
													<!-- <div class="custom-control custom-checkbox">
														<input v-model="check_all" @click.stop="checkAll" type="checkbox"  class="custom-control-input" id="checkedAll"><label class="custom-control-label" for="checkedAll"></label>
													</div> -->
													序号
												</th>
												<th>
													<div class="d-none d-lg-block">福利卡分类</div>
												</th>
												<th>
													<div class="d-none d-lg-block">企业</div>
												</th>

												<th>
													<div class="d-none d-lg-block">批次号</div>
												</th>
												
												<!-- <th>
													<div class="d-none d-lg-block">服务费类型/费率</div>
												</th> -->

												<th>
													<div class="d-none d-lg-block">面值(元)</div>
												</th>

												<th>
													<div class="d-none d-lg-block">可用金额(元)</div>
												</th>

												<th>
													<div class="d-none d-lg-block">卡类型</div>
												</th>

												<th>
													<div class="d-none d-lg-block">总数量</div>
												</th>
												
												<th>
													<div class="d-none d-lg-block">发放数量</div>
												</th>
												
												<th>
													<div class="d-none d-lg-block">核销数量</div>
												</th>
												
												<th>
													<div class="d-none d-lg-block">作废数量</div>
												</th>

												<!-- <th>
													<div class="d-none d-lg-block">有效期</div>
												</th>

												<th>
													<div class="d-none d-lg-block">关联制度</div>
												</th>

												<th>
													<div class="d-none d-lg-block">备注</div>
												</th>
												
												<th>
													<div class="d-none d-lg-block">制卡状态</div>
												</th>

												<th>
													<div class="d-none d-lg-block">批次状态</div>
												</th> -->
												<!-- <th>
													<div class="d-none d-lg-block"></div>
												</th>
												<th></th> -->
											</tr>
										</thead>

										<tbody>
											<tr v-bind:key="index" v-for="(item,index) in giftcardstocklist"
												:class="{'blue lighten-5':selectedIndex == index}">
												<td>
													{{index+1}}
													<!-- <label class="custom-control custom-checkbox">
														<input v-model="checkedIds" :value="item.id" :id="index" :disabled="item.enabled == -1" class="custom-control-input" type="checkbox"><label class="custom-control-label" :for="index"></label>
													</label> -->
												</td>
												<td>
													<div class="d-none d-lg-block">
														<!-- {{item.category}} -->
														<template v-if="categoryMap.hasOwnProperty(item.category)">
															{{categoryMap[item.category]}}
														</template>
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">
														<!-- {{item.dept_id}} -->
														<template v-if="deptmap.hasOwnProperty(item.dept_id)">
															{{deptmap[item.dept_id].department_name}}
														</template>
													</div>
												</td>


												<td>
													<div class="d-none d-lg-block">
														<span class="">
															{{item.batch_no}}
														</span>
													</div>
												</td>

												<!-- <td>
													<div class="d-none d-lg-block">
														<span class="font-weight-bold">
															{{expenseTypeMap[item.expense_type]}}
															/{{item.expense_rate}}%
														</span>
													</div>
												</td> -->

												<td>
													<div class="d-none d-lg-block"><span class="font-weight-bold">
															{{item.price}}
														</span>
													</div>
												</td>

												<td>
													<div class="d-none d-lg-block"><span class="font-weight-bold">
															{{item.amount}}
														</span>
													</div>
												</td>

												<td>
													<div class="d-none d-lg-block">
														{{item.card_type==1?'电子卡':'实体卡'}}
													</div>
												</td>

												<td>
													<div class="d-none d-lg-block">
														<span class="font-weight-bold">
															{{item.qty}}
														</span>
													</div>
												</td>
												
												<td>
													<div class="d-none d-lg-block">
														<span class="font-weight-bold">
															{{item.sended_qty}}
														</span>
													</div>
												</td>
												
												<td>
													<div class="d-none d-lg-block">
														<span class="font-weight-bold">
															{{item.used_qty}}
														</span>
													</div>
												</td>
												
												<td>
													<div class="d-none d-lg-block">
														<span class="font-weight-bold">
															{{item.cancel_qty}}
														</span>
													</div>
												</td>

												<!-- <td>
													<div class="d-none d-lg-block">
														{{item.expiry_date=='2099-12-31'?'长期':item.expiry_date}}
													</div>
												</td>

												<td>
													<div class="d-none d-lg-block">
														{{item.institution_name}}
														<br>
													{{item.giftcardstock_id}}
														<i class="icon icon-copy" />
													</div>
												</td>

												<td>

													<div v-if="item.remark" v-tooltip.top="''+item.remark+''"
														class="d-none d-lg-block text-ellipsis" style="width: 100px;">
														{{item.remark}}
													</div>

												</td>
												
												<td>
													<div class="d-none d-lg-block">
													
													<template v-if="item.card_type==0">
														<template v-if="item.print_status==0">
															<span class="text-danger">制卡中</span>
														</template>
														
														<template v-if="item.print_status==1">
															<span class="text-success">已制卡</span>
														</template>
													</template>
													
													</div>
												</td>


												<td>
													<div class="d-none d-lg-block">
													
														<template v-if="item.status==0">
															<span class="text-danger">待下发</span>
														</template>
														
														<template v-if="item.status==1">
															<span class="text-success">已下发</span>
														</template>
														
														<template v-if="item.status==-1">
															<span class="text-dark">已作废</span>
														</template>
													</div>
												</td> -->

												<!-- <td class="form-inline pt-4">


													<template v-if="user_role== 'ADMIN'">
														
														<template v-if="item.status==0">
															<button @click.stop="sendReady(index)" type="button"
																class="btn btn-outline-primary btn-xs ml-1"> 下发 </button>

															<button  @click.stop="edit(index)"
																type="button" class="btn btn-outline-primary btn-xs ml-1">
																编辑 </button>
														</template>	
														
														<template v-if="item.status==1">
															<button @click.stop="removeReady(index)"
																type="button" class="btn btn-outline-primary btn-xs ml-1">
																作废卡 </button>
																
															<button v-if="item.card_type==0 && item.print_status==0" 
															@click.stop="printReady(index)"
																type="button" class="btn btn-outline-primary btn-xs ml-1">
																完成制卡 </button>
														</template>	

													</template>



												</td> -->
											</tr>
										</tbody>
										<tfoot>
											<tr>
												<td colspan="7">
													<div class="d-none d-lg-block">
														<span class="">
															数量合计
														</span>
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">
														<span class="font-weight-bold">
															{{total_count}}
														</span>
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">
														<span class="font-weight-bold">
															{{total_send_count}}
														</span>
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">
														<span class="font-weight-bold">
															{{total_used_count}}
														</span>
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">
														<span class="font-weight-bold">
															{{total_cancel_count}}
														</span>
													</div>
												</td>
											</tr>
											<tr>
												<td colspan="7">
													<div class="d-none d-lg-block">
														<span class="">
															面值合计(元)
														</span>
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">
														<span class="font-weight-bold">
															{{total_amount}}
														</span>
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">
														<span class="font-weight-bold">
															{{total_send_amount}}
														</span>
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">
														<span class="font-weight-bold">
															{{total_used_amount}}
														</span>
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">
														<span class="font-weight-bold">
															{{total_cancel_amount}}
														</span>
													</div>
												</td>
											</tr>
										</tfoot>

									</table>
								</div>
								<div v-if="giftcardstocklist.length == 0">
									<div class="card-body text-lg-center m-5 height-300">
										<h5 class="mb-3">没有福利卡批次</h5>
										<!-- <button @click="add" type="button" class="btn btn-lg btn-secondary">
											创建制度
										</button> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- <div class="row" v-if="giftcardstocklist.length > 0">
					<div class="col-sm-12 col-md-5">
						<div class="dataTables_info" id="example2_info" role="status" aria-live="polite">
							显示 {{show_from}} ~ {{show_to}} 条 / 共 {{count}} 条
						</div>
					</div>
					<div class="col-sm-12 col-md-7">

						<v-pagination :total="total" :current-page='current' @pagechange="pagechange"></v-pagination>

					</div>
				</div> -->

			</div>

		</div>
		<!-- //main content -->

		<!-- modal -->


	</div>
</template>

<script>
	const __STOCKTYPE = {
		'NORMAL': '满减券',
		'DISCOUNT': '折扣券',
		'EXCHANGE': '换购券'
	}
	const __STOCKSTATE = {
		'RUNNING': '运行中',
		'UNAUDIT': '审核中',
		'EXPIRED': '已过期', //自定义状态
	}

	// import VPagination from '../components/my-page.vue'
	// import DatePicker from 'vue2-datepicker';
	// import 'vue2-datepicker/index.css';
	// import 'vue2-datepicker/locale/zh-cn';


	export default {
		data() {
			return {
				title: ' 制度列表',
				user_role: '',

				loader: {},

				token: '',
				outlet_id: 0,
				outlet_name: '',
				user_name: '',
				outlet_level: 0,

				setting: {},
				outletlist: [],

				query: '',

				current_page: 1,
				page_size: 50,
				count: 0,
				total_page: 0,
				show_from: 0,
				show_to: 0,

				total: 0, // 记录总条数
				display: 10, // 每页显示条数
				current: 1, // 当前的页数

				selectedIndex: -1,
				giftcardstock: {}, //选中的对象

				//////
				start_date: '',
				end_date: '',

				disabled: false,

				giftcardstocklist: [],

				enabled: -1,
				enabled_giftcardstocks: 0, //投放数
				expired: false,

				////
				departmentlist: [],
				dept_id: '',
				deptmap: {}, //key为 dept id, value为 dept
				expenseTypeMap: {
					// 'DEFAULT': '通用',
					// 'MEAL':'餐饮',
					// 'MALL':'商城',
					// 'METRO': '公交地铁',
					// 'PREPAYRECHARGE': '话费充值',
					// 'HOTEL': '酒店',
					// 'TRAIN': '火车票',
					// 'PLANE': '机票',
					// 'CAR': '用车',
					'ENT': '企业付',
					'STAFF': '员工付'
				},
				subexpenseTypeMap: {
					'DEFAULT': '通用',
					'REACH_SHOP': '到店',
					'TAKE_AWAY': '外卖',
					// 'ONLINE_MALL':'线上商城',
					// 'METRO': '公交地铁',
					// 'ONLINE_PREPAY_RECHARGE': '线上充值',
					// 'REACH_SHOP_HOTEL': '到店住宿',
					// 'ONLINE_HOTEL': '在线订酒店',
					// 'ONLINE_TRAIN': '火车票预订',
					// 'ONLINE_PLANE': '机票预订',
					// 'CAR': '用车',
				},
				ppMap: {
					'COMBINATION': '超额部分由个人支付',
					'PERSONAL': '整单全部由个人支付'
				},

				isLongTime: false,
				current_date: '',

				share_mode: false,

				standard_condition_info_list: [],

				giftcardstock_remark: '',

				////可读化使用条件
				ruleKeyMap: {
					"AREA": "可用位置",
					"MEAL_MERCHANT": "可用商户", //到店可用商户
					"QUOTA_TOTAL": "封顶金额",
					"MCC": "商户类型", //到店商户类型
					"SUPPLIER_CHANNEL": "供应商渠道",
					"ELM_MERCHANT": "可用商户", //外卖可用商户
					"TAKE_AWAY_CATEGORY": "商户类型", //外卖商户类型
					"SHIPPING_ADDRESS": "可用订餐地址",
					"BOOK_SWITCH": "预订",
					"MARKED_WORDS": "订餐说明"


				},
				//福利卡分类
				categorylist: this.GLOBAL.categorylist,
				// categorylist: this.GLOBAL.categorylist,
				selected_category: '',

				// categoryMap: {
				// 	"DEFAULT": "弹性福利",
				// 	"MEAL": "餐食外卖",
				// 	"CAR": "交通出行",
				// 	"COMMON": "工会福利",
				// },
				categoryMap: [],
				category: '',
				stockstatuslist: [{
						name: '未下发',
						value: 0
					},
					{
						name: '已下发',
						value: 1
					},
					{
						name: '已作废',
						value: -1
					},
				],
				stock_status: '',

				////
				valid_code: '',
				show: true,
				second: '',
				timer: null,
				
				price: '',
				card_type: '',
				cardtypelist: [
					// {name: '选择类型', value: ''},
					{name: '实体卡', value: '0'},
					{name: '电子卡', value: '1'},
				],
				total_count: 0,
				total_send_count: 0,
				total_used_count: 0,
				total_cancel_count: 0,
				total_amount: 0,
				total_send_amount: 0,
				total_used_amount: 0,
				total_cancel_amount: 0,
			}

		},
		components: {
			// VPagination,
			// DatePicker,
		},

		created: function() {
			console.log('--- created --- ')
			let self = this;

			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;

			//2.取得店铺信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			self.dept_id = lsLoginData.dept_id;
			self.user_role = lsLoginData.user_role;

			//构造categoryMap
			self.categoryMap = [];
			for (let i = 0; i < this.GLOBAL.categorylist.length; i++) {
				let et = this.GLOBAL.categorylist[i];
				self.categoryMap[et.value] = et.name;
			}
		},

		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;

			//先取预载数据，再取业务数据
			if (self.user_role == 'ADMIN') {
				self.init(() => {
					self.initData();
				})
			} else {
				self.initData();
			}
		},
		methods: {

			init(callback) {
				let self = this;

				//
				this.axios.get(this.GLOBAL.baseURI + 'getDepartmentList', {
						params: {
							token: self.token,
							dept_id: self.dept_id,
							// query: self.query,
							// current_page: self.current_page,
							// page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
							self.departmentlist = data.data.departments;

							for (let i = 0; i < self.departmentlist.length; i++) {
								let dept = self.departmentlist[i];
								self.deptmap[dept.id] = dept;
							}
							console.log('--- dept map ->', self.deptmap);

							self.current_date = data.data.current_date;

							if (typeof callback == 'function') {
								callback()
							}
						} else if (data.code == 101) {
							//登录超时
							// location.href = "index.html";
							self.logoff();
						} else {
							alert(data.message)
						}
					});
				//END

			},

			initData() {
				let self = this;

				self.loader = self.$loading.show();

				//调用接口
				self.axios.get(self.GLOBAL.baseURI + 'getGiftCardSummary', {
						params: {
							token: self.token,
							dept_id: self.dept_id,
							category: self.category,
							card_type: self.card_type, 
							price: self.price,
							query: self.query,
							// current_page: self.current_page,
							// page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);

						self.loader.hide();

						if (data.code == 200) {

							self.giftcardstocklist = data.data.list;

							// self.start_date = data.data.start_date;
							// self.end_date = data.data.end_date;

							// self.current_page = data.data.page_num;
							// self.page_size = data.data.page_size;
							// self.count = data.data.total;
							// self.total_page = Math.ceil(self.count / self.page_size);
							// console.log('--- self.total_page ->', self.total_page)

							// self.show_from = self.page_size * (self.current_page - 1) + 1;
							// self.show_to = self.page_size * self.current_page <= self.count ? self.page_size * self
							// 	.current_page : self.count;

							// self.total = self.count; // 记录总条数
							// self.display = self.page_size; // 每页显示条数
							// self.current = self.current_page; // 当前的页数

							// self.giftcardstocklist = []; //重置
							// for (let giftcardstock of data.data.list) {
							// 	// giftcardstock.typeDesc = __STOCKTYPE[giftcardstock.stock_type];
							// 	// giftcardstock.stateDesc = giftcardstock.stock_state?__STOCKSTATE[giftcardstock.stock_state]:""

							// 	self.giftcardstocklist.push(giftcardstock);
							// }
							
							self.total_count = data.data.total_count;
							self.total_send_count = data.data.total_send_count;
							self.total_used_count = data.data.total_used_count;
							self.total_cancel_count = data.data.total_cancel_count;
							self.total_amount = data.data.total_amount;
							self.total_send_amount = data.data.total_send_amount;
							self.total_used_amount = data.data.total_used_amount;
							self.total_cancel_amount = data.data.total_cancel_amount;
						} else if (data.code == 403) {
							// Vue.toasted.error( '没有权限', {
							// 	position: 'top-center',
							// 	duration: '2000',
							// })

							self.$router.push({
								name: '403'
							});

							return;
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)
							self.$toasted.error(data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END

			},

			pagechange: function(currentPage) {
				console.log(currentPage);
				// ajax请求, 向后台发送 currentPage, 来获取对应的数据
				this.current_page = currentPage;
				this.initData();
			},

			search() {
				let self = this;

				self.initData();
			},

			add() {
				let self = this;

				self.$router.push({
					name: 'addgiftcardstock',
					params: {
						outlet_id: self.outlet_id
					}
				})
			},

			edit(index) {
				let self = this;

				let giftcardstock = Object.assign({}, self.giftcardstocklist[index]);
				console.log(index, giftcardstock);

				self.$router.push({
					name: 'editgiftcardstock',
					query: {
						id: giftcardstock.id,
						nonce_str: giftcardstock.nonce_str,
					}
				})
			},


			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;

				self.init(() => {
					self.initData();
					self.employee_id = 0;
				})

			},

			sendReady(index) {
				console.log(index);
				let self = this;

				let giftcardstock = self.giftcardstocklist[index];
				console.log(index, giftcardstock);

				self.selectedIndex = index;
				self.giftcardstock = Object.assign({}, giftcardstock);

				if (self.giftcardstock.status != 0) {
					self.$toasted.error('批次不符合下发条件', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				self.valid_code = '';

				$('#iModal').modal('show');
			},

			//修改基本信息
			sendStock() {
				let self = this;

				console.log('--- self.giftcardstock ->', self.giftcardstock)

				if (self.giftcardstock.status != 0) {
					self.$toasted.error('批次不符合下发条件', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}

				if (!self.valid_code) {
					self.$toasted.error('请输入审批验证码', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				self.disabled = true;

				// 组装数据
				let postData = {
					token: self.token,
					// dept_id: self.giftcardstock.dept_id,
					id: self.giftcardstock.id,
					nonce_str: self.giftcardstock.nonce_str || '',

					valid_code: self.valid_code,
				}

				let postData2 = new FormData();
				for (var key in postData) {
					console.log(key + ':' + postData[key]);
					postData2.append(key, postData[key]);
				}

				//调用接口
				self.axios.post(self.GLOBAL.baseURI + 'sendGiftCardStock',
						postData2
					).then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						self.disabled = false;

						if (data.code == 200) {

							if (data.data.result_code == 'success') {
								//刷新修改的字段
								// let _giftcardstock = data.data.giftcardstock;
								// self.$set(self.giftcardstocklist, self.selectedIndex, _giftcardstock);

								self.$toasted.success('下发成功', {
									position: 'top-center',
									duration: '2000',
								})

								self.initData();

								$('#iModal').modal('hide');
							} else {
								self.$toasted.error(data.data.result_msg, {
									position: 'top-center',
									duration: '2000',
								})
							}

						} else if (data.code == 403) {
							// Vue.toasted.error( '没有权限', {
							// 	position: 'top-center',
							// 	duration: '2000',
							// })

							self.$router.push({
								name: '403'
							});

							return;
						} else {
							self.$toasted.error(data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END
			},
			
			printReady(index) {
				console.log(index);
				let self = this;
			
				let giftcardstock = self.giftcardstocklist[index];
				console.log(index, giftcardstock);
			
				self.selectedIndex = index;
				self.giftcardstock = Object.assign({}, giftcardstock);
			
				if (self.giftcardstock.print_status != 0) {
					self.$toasted.error('批次不符合完成制卡条件', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				self.valid_code = '';
			
			
				$('#pModal').modal('show');
			},
			
			printConfirm(){
				let self = this;
				
				console.log('--- self.giftcardstock ->', self.giftcardstock)
				
				if (self.giftcardstock.print_status != 0) {
					self.$toasted.error('批次不符合完成制卡条件', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				if (!self.valid_code) {
					self.$toasted.error('请输入审批验证码', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				self.disabled = true;
				
				// 组装数据
				let postData = {
					token: self.token,
					// dept_id: self.giftcardstock.dept_id,
					id: self.giftcardstock.id,
					nonce_str: self.giftcardstock.nonce_str || '',
					act: 'print',
					valid_code: self.valid_code,
				}
				
				let postData2 = new FormData();
				for (var key in postData) {
					console.log(key + ':' + postData[key]);
					postData2.append(key, postData[key]);
				}
				
				//调用接口
				self.axios.post(self.GLOBAL.baseURI + 'updateGiftCardStockStatus',
						postData2
					).then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						self.disabled = false;
				
						if (data.code == 200) {
				
							if (data.data.result_code == 'success') {
								//刷新修改的字段
								// let _giftcardstock = data.data.giftcardstock;
								// self.$set(self.giftcardstocklist, self.selectedIndex, _giftcardstock);
				
								self.$toasted.success('更新成功', {
									position: 'top-center',
									duration: '2000',
								})
				
								self.initData();
				
								$('#pModal').modal('hide');
							} else {
								self.$toasted.error(data.data.result_msg, {
									position: 'top-center',
									duration: '2000',
								})
							}
				
						} else if (data.code == 403) {
							// Vue.toasted.error( '没有权限', {
							// 	position: 'top-center',
							// 	duration: '2000',
							// })
				
							self.$router.push({
								name: '403'
							});
				
							return;
						} else {
							self.$toasted.error(data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END
			},

			removeReady(index) {
				console.log(index);
				let self = this;
			
				let giftcardstock = self.giftcardstocklist[index];
				console.log(index, giftcardstock);
			
				self.selectedIndex = index;
				self.giftcardstock = Object.assign({}, giftcardstock);
			
				if (self.giftcardstock.status == -1) {
					self.$toasted.error('批次不符合作废条件', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				self.valid_code = '';
			
			
				$('#rModal').modal('show');
			},

			removeConfirm(index) {
				let self = this;
				
				console.log('--- self.giftcardstock ->', self.giftcardstock)
				
				if (self.giftcardstock.status == -1) {
					self.$toasted.error('批次不符合完成制卡条件', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				if (!self.valid_code) {
					self.$toasted.error('请输入审批验证码', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				self.disabled = true;
				
				// 组装数据
				let postData = {
					token: self.token,
					// dept_id: self.giftcardstock.dept_id,
					id: self.giftcardstock.id,
					nonce_str: self.giftcardstock.nonce_str || '',
					act: 'cancel',
					valid_code: self.valid_code,
				}
				
				let postData2 = new FormData();
				for (var key in postData) {
					console.log(key + ':' + postData[key]);
					postData2.append(key, postData[key]);
				}
				
				//调用接口
				self.axios.post(self.GLOBAL.baseURI + 'updateGiftCardStockStatus',
						postData2
					).then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						self.disabled = false;
				
						if (data.code == 200) {
				
							if (data.data.result_code == 'success') {
								//刷新修改的字段
								// let _giftcardstock = data.data.giftcardstock;
								// self.$set(self.giftcardstocklist, self.selectedIndex, _giftcardstock);
								let count = data.data.count;
								
								self.$toasted.success('作废成功，共作废'+count+'张', {
									position: 'top-center',
									duration: '3000',
								})
				
								self.initData();
				
								$('#rModal').modal('hide');
							} else {
								self.$toasted.error(data.data.result_msg, {
									position: 'top-center',
									duration: '2000',
								})
							}
				
						} else if (data.code == 403) {
							// Vue.toasted.error( '没有权限', {
							// 	position: 'top-center',
							// 	duration: '2000',
							// })
				
							self.$router.push({
								name: '403'
							});
				
							return;
						} else {
							self.$toasted.error(data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END
			},

			//删除制度
			remove() {
				let self = this;

				console.log('--- self.giftcardstock ->', self.giftcardstock)

				self.disabled = true;

				// 组装数据
				let postData = {
					token: self.token,
					dept_id: self.giftcardstock.dept_id,
					ins_id: self.giftcardstock.id,
					nonce_str: self.giftcardstock.nonce_str || '',
				}

				let postData2 = new FormData();
				for (var key in postData) {
					console.log(key + ':' + postData[key]);
					postData2.append(key, postData[key]);
				}

				//调用接口
				self.axios.post(self.GLOBAL.baseURI + 'cancelGiftCardStock',
						postData2
					).then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						self.disabled = false;

						if (data.code == 200) {

							if (data.data.result_code == 'success') {
								let _giftcardstock = data.data.giftcardstock;
								// self.$set(self.giftcardstocklist, self.selectedIndex, _giftcardstock);

								self.giftcardstocklist.splice(self.selectedIndex, 1)

								self.$toasted.success('操作成功', {
									position: 'top-center',
									duration: '2000',
								})

								// $('#iModal').modal('hide');
							} else {
								self.$toasted.error(data.data.result_msg, {
									position: 'top-center',
									duration: '2000',
								})
							}

						} else if (data.code == 403) {
							// Vue.toasted.error( '没有权限', {
							// 	position: 'top-center',
							// 	duration: '2000',
							// })

							self.$router.push({
								name: '403'
							});

							return;
						} else {
							self.$toasted.error(data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END
			},


			////
			sendMessage(e) {
				let self = this;

				// self.$toasted.success('发送成功', {
				// 	position: 'top-center',
				// }).goAway(2000)

				// //倒计时
				// self.countdown();
				
				let act = e.currentTarget.dataset.key;//动作

				if (self.disabled) return;

				self.disabled = true;

				// 组装数据
				let postData = {
					token: self.token,
					act: act,//动作：send下发，cancel作废
				}

				console.log('--- postData ->', postData)

				this.axios.get(this.GLOBAL.baseURI + 'sendGiftCardStockMessage', {
						params: postData
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);

						self.disabled = false;

						if (data.code == 200) {

							self.$toasted.success('发送成功', {
								position: 'top-center',
							}).goAway(2000)

							//倒计时
							self.countdown();
						} else if (data.code == 403) {
							self.$router.push({
								name: '403'
							});
							return;
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}
					});
			},

			countdown() {
				const TIME_COUNT = 60;
				if (!this.timer) {
					this.second = TIME_COUNT;
					this.show = false;
					this.timer = setInterval(() => {
						if (this.second > 0 && this.second <= TIME_COUNT) {
							this.second--;
						} else {
							this.show = true;
							clearInterval(this.timer);
							this.timer = null;
						}
					}, 1000)
				}
			},
		},
	}
</script>

<style>
	.mx-icon-calendar,
	.mx-icon-clear {
		right: 28px !important;
	}

	.text-ellipsis {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>